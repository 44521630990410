<template>
  <div class="pa-2">
    <h1 class="headline mb-12 font-weight-bold">
      プライバシーポリシー
    </h1>
    <h2 class="title mb-2 font-weight-black">
      個人情報保護方針
    </h2>
    <p class="body-1 mb-1">
      「公認会計士試験過去問コム」(以下、当サイト)は、当サイトの利用者（以下「ユーザー」という）に関する個人情報の取扱いについて、以下のとおりプライバシーポリシーを定めます。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      個人情報の管理
    </h2>
    <p class="body-1 mb-1">
      当サイトは、ユーザーの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      個人情報の利用目的
    </h2>
    <p class="body-1 mb-1">
      当サイトは、以下の目的のため、個人情報を利用します。
    </p>
    <ul>
      <li>当該サービス提供のため</li>
      <li>お問い合わせに対する回答のため</li>
      <li>サイト利用登録のため</li>
      <li>統計的資料作成を行う際の基礎データとして利用すること</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      個人情報の第三者への開示・提供の禁止
    </h2>
    <p class="body-1 mb-1">
      当サイトは利用目的を達成するための必要な範囲で、個人情報を業務委託先に提供することがあります。この場合においても、当社は業務委託先に対し、提供した個人情報の適正な取り扱いを求めるとともに適切な管理をいたします。また、以下の場合に、事前に本人の同意を得ることなく、必要な個人情報を関係する第三者に提供することがあります。
    </p>
    <ul>
      <li>法令に基づく場合</li>
      <li>ユーザー(ご本人)の同意がある場合</li>
      <li>法令に基づき開示することが必要である場合</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      個人情報の安全対策
    </h2>
    <p class="body-1 mb-1">
      当サイトは、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      ご本人の照会
    </h2>
    <p class="body-1 mb-1">
      ユーザー(ご本人)の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      広告の配信について
    </h2>
    <ul>
      <li>当サイトは第三者配信の広告サービス「Google Adsense グーグルアドセンス」を利用しています。</li>
      <li>広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。</li>
      <li>Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「広告 – ポリシーと規約 – Google」をご覧ください。</li>
      <li>第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにCookie（クッキー）を設定したりこれを認識したりする場合があります。</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      アクセス解析ツールについて
    </h2>
    <ul>
      <li>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。</li>
      <li>このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは<a href="https://marketingplatform.google.com/about/analytics/terms/jp/">ここをクリック</a>してください。</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      法令、規範の遵守と見直し
    </h2>
    <p class="body-1 mb-1">
      当サイトは、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
    </p>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      プライバシーポリシーの変更
    </h2>
    <ul>
      <li>本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</li>
      <li>当サイトが別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</li>
    </ul>
    <v-divider class="ma-6" />
    <h2 class="title mb-2 font-weight-black">
      お問い合せ
    </h2>
    <p class="body-1 mb-1">
      当サイトの個人情報の取扱に関するお問い合せは、お問い合わせフォームよりご連絡ください。
    </p>
  </div>
</template>

<script>
  export default {
    name: "PrivacyPolicy"
  }
</script>

<style scoped>

</style>
